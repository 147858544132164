<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-toolbar class="primary white--text d-block d-md-none" max-height="56">
      <v-toolbar-title>Adresser un patient</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-0 row-dialog">
      <modals-sidebar title="Adresser le patient" class="d-none d-md-block" />
      <v-col cols="12" md="11">
        <v-form ref="form" lazy-validation class="pt-4" @submit.prevent="submit">
          <template v-if="professionalUid">
            <form-category title="Identité patient" subtitle="Selectionnez le patient à adresser" class="mb-5" />
            <form-user-search-field v-model="form.patient" required
                                    :filters="{type: 'patient', 'patient_professional': $auth.user.sub}"
            />
          </template>

          <template v-if="patientUid">
            <form-category
              title="Professionnel de santé"
              subtitle="Selectionnez le professionnel de santé dont vous souhaitez adresser ce patient"
              class="mb-5"
            />
            <form-user-search-field v-model="form.professional" required :filters="{type: 'professional'}" />
          </template>

          <form-category class="mt-6 mb-3" title="Informations médicales"
                         subtitle="Informations médicales complémentaires du patient"
          />
          <v-select v-model="form.criticality_level" label="Criticité de la demande"
                    :items="availableCriticalityLevels"
                    :rules="[rules.required]"
          />

          <v-textarea v-model="form.reason" label="Motif de la demande"
                      auto-grow rows="2"
                      :rules="[rules.required]"
                      maxlength="2000"
          />

          <v-textarea v-model="form.medical_history" label="Antécédents médicaux significatifs"
                      auto-grow rows="2"
                      maxlength="2000" :rules="[rules.required]"
          />

          <v-textarea v-model="form.ongoing_treatments"
                      label="Traitements en cours et renseignements cliniques pertinents"
                      auto-grow rows="2"
                      maxlength="2000" :rules="[rules.required]"
          />
          <v-card-actions class="justify-center">
            <v-btn color="primary" x-small type="submit" class="my-2" :loading="submitting" :disabled="submitting">
              Adresser le patient
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import { rules } from '@/services/rules'
  import FormUserSearchField from '@/modules/core/components/FormUserSearchField'
  import { referPatient } from '@/modules/professionals/api'

  export default {
    name: 'ReferralForm',
    components: {FormUserSearchField, FormCategory, ModalsSidebar},
    props: {
      patientUid: String,
      professionalUid: String
    },
    data() {
      return {
        dialog: false,
        submitting: false,
        rules,
        availableCriticalityLevels: [
          'Immédiate (< 24 H)',
          'Sans délai (< 72 H)',
          'Rapide (4 à 15 J)',
          'Sans urgence (> 15 J)'
        ],
        form: {
          patient: null,
          professional: null,
          criticality_level: null,
          reason: null,
          medical_history: null,
          ongoing_treatments: null
        }
      }
    },
    methods: {
      async submit() {
        let professionalUid = this.professionalUid || (this.form.professional ? this.form.professional.uid : null)
        let patientUid = this.patientUid || (this.form.patient ? this.form.patient.uid : null)

        if (this.submitting || !this.$refs.form.validate() || !professionalUid || !patientUid) {
          return
        }

        try {
          this.submitting = true
          await referPatient(professionalUid, patientUid,this.form.criticality_level, this.form.reason, this.form.medical_history, this.form.ongoing_treatments)
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Patient adressé',
            subtitle: 'Patient adressé avec succès.'
          })
          this.$emit('close')
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Une erreur est survenue veuillez réessayer.'
          })
          throw e
        } finally {
          this.submitting = false
        }
      }
    }
  }
</script>

<template>
  <v-autocomplete
    v-model="localValue"
    :items="items"
    :loading="searching"
    :search-input.sync="searchInput"
    item-text="full_name"
    hide-no-data
    item-value="uid"
    :placeholder="'Rechercher un '+placeholder"
    no-filter
    dense
    clearable
    return-object
    :rules="required ? [rules.required] : []"
  >
    <template v-slot:item="data">
      <div>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.full_name }}</v-list-item-title>
          <v-list-item-subtitle class="secondary--text text-caption">
            <template v-if="data.item==='user:patient' && data.item.birth_date">
              {{ $dayjs(data.item.birth_date).format('DD/MM/YYYY') }}
            </template>
            <template v-else-if="data.item==='user:professional'">
              {{ data.item.job.name }}
            </template>
          </v-list-item-subtitle>
        </v-list-item-content>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
  import { debounce } from 'throttle-debounce'
  import { rules } from '@/services/rules'
  import { search } from '@/modules/user/api'

  export default {
    name: 'FormUserSearchField',
    props: {
      value: Object,
      filters: Object,
      required: Boolean
    },
    data() {
      return {
        rules,
        searching: false,
        items: this.value ? [this.value] : [],
        searchInput: null,
        localValue: this.value
      }
    },
    computed: {
      placeholder() {
        switch (this.filters.type) {
          case 'professional':
            return 'professionnel de santé'
          case 'patient':
            return 'patient'
          default:
            return 'utilisateur'
        }
      }
    },
    watch: {
      searchInput: debounce(300, function (searchTerm) {
        if (!searchTerm || (this.localValue && searchTerm === this.localValue.full_name)) return
        this.loadingSearch = true
        this.searchUser(searchTerm)
      }),
      localValue(value) {
        this.$emit('input', value)
      }
    },
    methods: {
      async searchUser(searchTerm) {
        this.searching = true
        try {
          this.items = (await search(1, 10, {
            ...this.filters,
            search_term: searchTerm
          }, null, ['professional_job'])).data.list
        } finally {
          this.searching = false
        }
      }
    }
  }
</script>
